.glass {
  /* font-size: clamp(1rem, -0.875rem + 2.333vw, 2vh); */
  /* font-size: clamp(1rem, 0.5rem, 2vh); */
  font-size: 2.5vh;
  text-align: center;
  color: 'blue'
}

@media screen and (max-width: 480px) {
  .glass {
    font-size: 2vh
  }
  h1 {
    font-size: 5vw;
  }
}