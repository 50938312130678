.bookbtn {
    background-color: white;
    color: #043b9b;;
    /* font-family: Trebuchet MS; */
    font-size: 12px;
    /* font-weight: 800; */
    /* font-style: normal; */
    text-decoration: none;
    padding: 6px 7px;
    border: 2px solid #043b9b;
    border-radius: 5px;
    /* display: inline-block; */
}

.bookbtn:hover {
    background-color: #043b9b;
    color: white;
}

.bookbtn:active {
    transform: scale(0.95);
}