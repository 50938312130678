.imgStyle {

  max-width: 300px;
  margin-top: 8px;

}

.artLayout {
  display: flex;
}


@media (max-width: 650px) {
    /*.nav {
      display: none;*/
      /*position: fixed;
      bottom: 0;*/
    /*}*/
  
    /* .nav_text {
      display: none;
    } */
    /* .artpage {
      background-color: green;
    }
    .artLayout {
      background-color: blue;
    } */

    .imgStyle {

        max-width: 180px;
        margin-top: 5px;

    }
  }